import React from "react";
import Bitmap from '../../Assets/images/Bitmap.png';
import bit from '../../Assets/images/bit.png';
import path from '../../Assets/images/path.png';
import map from '../../Assets/images/map.png';
import about from '../../Assets/images/about1.png';
import inv from '../../Assets/images/inv.png';

const AboutCards = () => {
  return (
    <React.Fragment>
      <section className="banner_inde padding_tb ">
        <div className="container">
          <div className="two_grids">
            <div className="inr_grid">
              <h3 className="gensis-font p-color">WHAT WE DO</h3>
              <h2 className="M-Bold_60 hd-color MR-reg-60-1">Investments.</h2>
              <h2 className="M-Bold_60 hd-color MR-reg-60-1">Property Management.</h2>
              <h2 className="M-Bold_60 hd-color MR-reg-60-1">Security.</h2>
              <p className="hd-color">
              Investing in your future has never been easier with Vortexx. With a team of experienced and knowledgeable professionals Vortexx is dedicated to helping you achieve your financial goals. 
              Whether you're looking to diversify your investment portfolio, establish management for your assets or simply secure them, we have a variety of solutions to fit your unique needs and preferences.
              With a wide range of investment options, cutting-edge technologies, and a commitment to exceptional customer service, you can trust Vortexx to guide you every step of the way. 
              Join us today and experience the peace of mind that comes with working with Vortexx, let us guide you to greater, stronger financial success.
              </p>
            </div>
            
          </div>
        </div>
      </section>
      <section className="exerci padding_tb bg_color">
        <div className="container">
          <div className="grid_rtwo">
            <div className="inr_grid wid">
              <img src={inv} />
            </div>
            <div className="inr_grid">
              <h3 className="hd-color MR-reg-60">
                <span className="p-color M-Bold_60">Investments <br /></span>
                Simplified
              </h3>
              <p className=" hd-color">
                We'll work closely with you to create an investment portfolio designed to meet your
                specific requirements and goals. We'll assist in managing your investment(s) and even monitor them with our best in class security offerings.
              </p>
              <ul className="main_ul">
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  We'll assist you in balancing your portfolio with smart investment strategies.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Bring value to your portfolio while keeping risk under control.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Benefit from annual follow-ups on your portfolio so you can identify which
                  specific shares is pulling your portfolio upwards or downwards.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Achieve the minimum of the return you wanted so you can redistribute the assets or
                  even increase your capital.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Switch between strategies with ease with our flexible blueprints.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="tation padding_tb">
        <div className="container">
        <div className="inr_grid wid mob-remove-1">
              <img src={map} />
            </div>
          <div className="grid_mtwo">
            <div className="inr_grid">
              <h3 className="hd-color MR-reg-60">
                Property Management
                <br />
                <span className="p-color M-Bold_60">Simplified</span>
              </h3>
              <p className=" hd-color">
                As an entrepreneur, you’re always looking for new ways to become more
                efficient and more cost-effective. That's why we focus on
                providing you with a wide range of dependable and cost-efficient
                services that will help you succeed. 
              </p>
              <p className=" hd-color">
                We’ve worked with businesses of all sizes, from local startups to established
                corporations, so we can help you find the right solution based around your requirements.
                
              </p>
              <ul className="main_ul">
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Maintenance and Repairs: Property managers are responsible for overseeing 
                  maintenance and repair work to ensure that properties are kept in good condition
                  and are safe for tenants to occupy. They can also negotiate contracts with contractors 
                  and suppliers to get the best prices and quality of work.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Rent Collection: Vortexx management handles rent collection and can take legal action against tenants who do not pay their rent. 
                  Our staff also maintains accurate records of rent and financial statements for the property owner.{' '}
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Marketing and Tenant Acquisition: Our staff will market the property and screen potential tenants to find the best fit for your property. 
                  We handle lease agreements, move-in and move-out processes, and other administrative tasks related to tenant management.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Compliance with Laws and Regulations: Vortexx assists in keeping your proeprty up-to-date with local, state, and federal laws and regulations related to your property.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Conflict Resolution: Our staff will manage your disputes and can mediate to resolve any conflicts that arise.
                </li>
              </ul>
            </div>
            <div className="inr_grid wid mob-remove">
              <img src={map} />
            </div>
          </div>
        </div>
      </section>
      <section className="exerci padding_tb bg_color">
        <div className="container">
          <div className="grid_rtwo">
            <div className="inr_grid wid">
              <img src={about} />
            </div>
            <div className="inr_grid">
              
              <h3 className="hd-color MR-reg-60">
                <span className="p-color M-Bold_60">
                  Asset Management
                  <br />
                </span>
                Simplified
              </h3>
              <p className=" hd-color">
                We specializes in helping investors maximize the performance, return and growth of their
                assets. Whether its a vehicle, property, VPP or asset we can assist. Our team combines tried-and-tested strategies with data-driven market insights
                to help our clients make informed, precise decisions.
              </p>
              <ul className="main_ul">
                <li className=" hd-color list-inline-item">
                  {' '}
                  <img src={path} />
                  Optimize Your Porfolios Performance- We’ll take full control of your assets and resources,
                  manage them and provide reports to help you enhance and grow your assets.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Navigate Risks With Confidence - Depending on the type of asset we will
                  provide recommendations and resources to reduce risk. A team member will also work with you to create
                  a plan for dealing with any potential issues that may come up in the future—from
                  minor nuisances like plumbing problems to major issues like fires or floods.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Receive Strategic Marketing Recommendations - We’ll help you understand and determine where you want to go next. 
                  Though our dedicated partners, market research, analysis, and advice you can make informed decisions about your
                  investments in a timely manner.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AboutCards;
