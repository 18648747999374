import Bitmap from '../Assets/images/Bitmap.png';
import oct from '../Assets/images/oct.png';
import atl from '../Assets/images/atl.png';
import bel from '../Assets/images/bel.png';
import cam from '../Assets/images/cam.png';

export const portfolioData = [
  
  {
    img: oct,
    heading: 'Portfolio',
    title: 'Octave Apartments',
    right: true,
    description:
      'Octave is a place to live like no other place. Truly unique, designed dwellings in the heart of Nashville. Minutes from a long list of bars, restaurants, boutiques on 8th Ave South. With premium finishes, wood style flooring and wide-open spaces, each floor plan from Octave brings you unparalleled luxury without sacrificng on comfort. In addition residents have a long list of amenities at their finger tips. For example, a hotel style pool, 24-hour fitness center, off-leash dog park, controlled access parking garage, bocce ball, free cruiser bicycle rentals and much more. Why live anywhere else, give us a call today to find out more!'
  },
  
  {
    img: bel,
    title: 'Belle Arbor Townhomes',
    right: false,
    description:
      'Belle Arbor Townhomes, is an exceptional community that offers a high quality of living. With a prime location just minutes away from top shopping, dining, and entertainment venues near downtown Nashville. Belle Arbor offers exceptional amenities, including a fitness center, and clubhouse. Come see for yourself why Belle Arbor Townhomes is a great place to call home!'
  },
  {
    img: cam,
    title: 'Camden Vantage',
    right: true,
    description:
      'Camden Vantage Apartments is an exceptional place to call home. With its prime location offering residents easy access to major highways, public transportation, and top shopping, dining, and entertainment venues. Amenities include a rooftop pool with stunning city views, a state-of-the-art fitness center, and an outdoor lounge area with grills, perfect for social gatherings. The apartment units themselves are modern and spacious, featuring stainless steel appliances and granite countertops. Residents can also rest easy knowing that the community has a 24-hour concierge service and package receiving service. '
  },
  {
    img: atl,
    title: 'Atlas Apartments',
    right: false,
    description:
      'Escape the everyday. Imagine yourself walking in the midst of a thriving urban oasis. New experiences and amenities await you at every turn. Light, sound, and music fill your senses with wanderlust. Each step brings you closer to expansive studio, one, and two bedroom floor plans, complete with soaring ceilings and unsurpassed finishes. This is a new breed of luxury apartment homes. Welcome to ATLAS, a community enriched in culture and immersed in one of Nashvilles most historic neighborhoods. A home for the free spirit and a refuge for those who crave the extraordinary. Discover us today in the heart of Germantown and let the adventure begin.'
  },
  
];
