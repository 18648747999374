import React from "react";
import { Helmet } from 'react-helmet';

function Privacy() {
  return (
    <React.Fragment>
      <Helmet>
            <title>Privacy</title>
            <meta name="description" content="Privacy Policy." />
        </Helmet>
      <section className="banner_inde padding_tb">
        <div className="container d-flex flex-column gap-5">
          <div className="inr_grid d-flex justify-content-center flex-column">
            <h2 className="MR-reg-30">Privacy Policy</h2>
            <p className="MM_para_18 hd-color ">
              At Vortexx, accessible from https://vortexx.co, one of our main priorities is the
              privacy of our visitors. This Privacy Policy document contains types of information
              that is collected and recorded by Vortexx and how we use it.
            </p>
            <p className="MM_para_18 hd-color ">
              If you have additional questions or require more information about our Privacy Policy,
              do not hesitate to contact us.
            </p>
            <p className="MM_para_18 hd-color ">
              This Privacy Policy applies only to our online activities and is valid for visitors to
              our website with regards to the information that they shared and/or collect in
              Vortexx. This policy is not applicable to any information collected offline or via
              channels other than this website.
            </p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">Consent</h2>
            <p className="MM_para_18 hd-color ">
              By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            </p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">Information we collect</h2>
            <p className="MM_para_18 hd-color ">
              We do not sell any information. Additional information can be seen below under the
              CCPA section. The personal information that you are asked to provide, and the reasons
              why you are asked to provide it, will be made clear to you at the point we ask you to
              provide your personal information.
            </p>
            <p className="MM_para_18 hd-color text-left">
              If you contact us directly, we may receive additional information about you such as
              your name, email address, phone number, the contents of the message and/or attachments
              you may send us, and any other information you may choose to provide. When you
              register for an Account, we may ask for your contact information, including items such
              as name, company name, address, email address, and telephone number.
            </p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">How we use your information</h2>
            <p className="MM_para_18 hd-color text-left">
              We use the information we collect in various ways, including to:
            </p>
            <p className="MM_para_18 hd-color text-left">
              Provide, operate, and maintain our website
            </p>
            <p className="MM_para_18 hd-color text-left">
              Improve, personalize, and expand our website
            </p>
            <p className="MM_para_18 hd-color text-left">
              Understand and analyze how you use our website
            </p>
            <p className="MM_para_18 hd-color text-left">
              Develop new products, services, features, and functionality
            </p>
            <p className="MM_para_18 hd-color text-left">
              Communicate with you, either directly or through one of our partners, including for
              customer service, to provide you with updates and other information relating to the
              website, and for marketing and promotional purposes
            </p>
            <p className="MM_para_18 hd-color text-left">Send you emails</p>
            <p className="MM_para_18 hd-color text-left">Find and prevent fraud</p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">Cookies and Web Beacons</h2>
            <p className="MM_para_18 hd-color ">
              Like any other website, Vortexx uses ‘cookies’. These cookies are used to store
              information including visitors’ preferences, and the pages on the website that the
              visitor accessed or visited. The information is used to optimize the users’ experience
              by customizing our web page content based on visitors’ browser type and/or other
              information.
            </p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">Google Cookies</h2>
            <p className="MM_para_18 hd-color ">
              Google is one of a third-party vendor on our site. It also uses cookies, known as DART
              cookies, to serve ads to our site. However, visitors may choose to decline the use of
              DART cookies by visiting the Google ad and content network Privacy Policy at the
              following URL https://policies.google.com/technologies/ads
            </p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">Advertising Partners Privacy Policies</h2>
            <p className="MM_para_18 hd-color ">
              You may consult this list to find the Privacy Policy for each of the advertising
              partners of Vortexx.
            </p>
            <p className="MM_para_18 hd-color text-left">
              Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or
              Web Beacons that are used in their respective advertisements and links that appear on
              Vortexx, which are sent directly to users’ browser. They automatically receive your IP
              address when this occurs. These technologies are used to measure the effectiveness of
              their advertising campaigns and/or to personalize the advertising content that you see
              on websites that you visit.
            </p>
            <p className="MM_para_18 hd-color text-left">
              Note that Vortexx has no access to or control over these cookies that are used by
              third-party advertisers.
            </p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">Third Party Privacy Policies</h2>
            <p className="MM_para_18 hd-color ">
              Vortexx’s Privacy Policy does not apply to other advertisers or websites. Thus, we are
              advising you to consult the respective Privacy Policies of these third-party ad
              servers for more detailed information. It may include their practices and instructions
              about how to opt-out of certain options.
            </p>
            <p className="MM_para_18 hd-color ">
              You can choose to disable cookies through your individual browser options. To know
              more detailed information about cookie management with specific web browsers, it can
              be found at the browsers’ respective websites.
            </p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </h2>
            <p className="MM_para_18 hd-color ">
              Under the CCPA, among other rights California consumers have the right to: Request
              that a business that collects a consumer’s personal data disclose the categories and
              specific pieces of personal data that a business has collected about consumers.
            </p>
            <p className="MM_para_18 hd-color ">
              Request that a business delete any personal data about the consumer that a business
              has collected.
            </p>
            <p className="MM_para_18 hd-color ">
              Request that a business that sells a consumer’s personal data, not sell the consumer’s
              personal data.
            </p>
            <p className="MM_para_18 hd-color ">
              If you make a request, we have one month to respond to you. If you would like to
              exercise any of these rights, please contact us.
            </p>
          </div>
          <div className="inr_grid">
            <h2 className="MR-reg-30 ">GDPR Data Protection Rights</h2>
            <p className="MM_para_18 hd-color ">
              We would like to make sure you are fully aware of all of your data protection rights.
              Every user is entitled to the following:
            </p>
            <p className="MM_para_18 hd-color ">
              The right to access – You have the right to request copies of your personal data. We
              may charge you a small fee for this service.
            </p>
            <p className="MM_para_18 hd-color ">
              The right to rectification – You have the right to request that we correct any
              information you believe is inaccurate. You also have the right to request that we
              complete the information you believe is incomplete.
            </p>
            <p className="MM_para_18 hd-color ">
              The right to erasure – You have the right to request that we erase your personal data,
              under certain conditions.
            </p>
            <p className="MM_para_18 hd-color ">
              The right to restrict processing – You have the right to request that we restrict the
              processing of your personal data, under certain conditions.
            </p>
            <p className="MM_para_18 hd-color ">
              The right to object to processing – You have the right to object to our processing of
              your personal data, under certain conditions.
            </p>
            <p className="MM_para_18 hd-color ">
              The right to data portability – You have the right to request that we transfer the
              data that we have collected to another organization, or directly to you, under certain
              conditions.
            </p>
            <p className="MM_para_18 hd-color ">
              If you make a request, we have one month to respond to you. If you would like to
              exercise any of these rights, please contact us.
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Privacy;
