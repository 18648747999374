import React from "react";
import arrowOrange from '../../Assets/images/org-arrow.png';

const InfoSection = (data) => {
  return (
    <section className="padding_tb blk-bg sec-blk-bg">
      <div className="container">
        <div className="hm-bg-black">
          <h3 className="gensis-font p-color">{data.heading}</h3>
          <h3 className="M-Bold_46 hd-wht">{data.subHeading}</h3>
        </div>
        <div className="hm-grid-thr">
          {data.card.map((item, ind) => {
            return (
              <div key={ind} className="inr-hm-grid-thr fr_inr-lines">
                <div className="inr-dp-line-gr" />
                <h3 className="MR-SB-28 hd-wht">
                  {item.heading} <br />
                  <span className="M-Bold_46">{item.span}</span>
                </h3>
                <p className="hd-wht">{item.subHeading}</p>
                <a className="get-d mt-2 mt-lg-4" href="#">
                  Learn More <img src={arrowOrange} className="img-auto ms-2" />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
