import React from "react";
const Faq = () => {
  return (
    <section className="accordian padding_tb bg_color">
      <div className="container">
        <div className="faq_sec">
          <div className="faq-hd mb-2 mb-lg-5 text-center">
            <h3 className="hd-color MR-reg-60 ">
              Frequently Asked
              <span className="p-color M-Bold_60"> Questions</span>
            </h3>
          </div>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button Mb_bold_22 hd-color"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne ">
                  What documents or information do I need to complete the application
                  <br /> and screening process?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div className="accordion-body ">
                  <p>
                    We require the following from the owner:{' '}
                    <strong>
                      Copy of the Title, Copy of the Deed Restrictions, Copy of the Deed of Sale (if
                      the Title is not yet available), or, Copy of the Contract to Sell (if the
                      Title and the Deed of Sale are not yet available), Government-issued ID
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed Mb_bold_22 hd-color"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  When can I expect my property to be tenanted?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  It depends on several factors, but based on our experience it takes about 1 to 3
                  months to find a tenant.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed Mb_bold_22 hd-color"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  How does property inspection work?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  The property manager conducts regular inspections when accepting the rental, at
                  the halfway point of a lease term, and when a tenant moves out
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed Mb_bold_22 hd-color"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  What do you usually look for when screening tenants?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <strong>
                    Proof of identity, a good credit history, and a stable source of income are our
                    basic requirements.
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
