import React from "react";
import Appointment from '../../Component/Appointment';
import AboutCards from '../../Component/AboutCards';
import { Helmet } from 'react-helmet';

function Terms() {
  return (
    <React.Fragment>
      <Helmet>
            <title>Terms and Conditions</title>
            <meta name="description" content="Terms and Conditions." />
        </Helmet>
      <AboutCards />
      <Appointment />
    </React.Fragment>
  );
}

export default Terms;
