import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import './App.css';
import './Assets/css/media-query.css';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Router from './routes/routes';
import ScrollToTop from './Component/ScrollToTop';
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Router />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
