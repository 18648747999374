import React from 'react';
import { portfolioData } from '../../mocks/portfolio-data';
import { Helmet } from 'react-helmet';

function Portfolio() {
  return (
    <React.Fragment>
      <Helmet>
            <title>Portfolio</title>
            <meta name="description" content="Grow and develop a strong portfolio with Vortexx. Our Management, Investment and Security offerings help you to strengthen your assets and porfolio." />
        </Helmet>
      {portfolioData.map((item, ind) => {
        return (
          
          <section key={ind} className="banner_inde padding_tb flex-mob">
            
            <div className="container">
            <h3 className="gensis-font p-color padding_tb">{item.heading}</h3>
            <div className="inr_grid mob-remove-1">
                  <img src={item.img} />
                  <h2 className="MR-reg-60 MR-reg-60-1">{item.title}</h2>
                  <p className="hd-color">{item.description}</p>
                </div>

              <div className="mob-remove">
              <div className={item.right ? 'two_grids_right' : 'two_grids_left'}>
                <div className="inr_grid">
                  <h2 className="MR-reg-60 MR-reg-60-1">{item.title}</h2>
                  <p className="hd-color">{item.description}</p>
                </div>
                <div className="inr_grid">
                  <img src={item.img} />
                  
                </div>
              </div>
              </div>
            </div>
          </section>
        );
      })}
    </React.Fragment>
  );
}

export default Portfolio;
