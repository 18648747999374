import React from "react";
import Appointment from '../../Component/Appointment';
import AboutCards from '../../Component/AboutCards';
import {Helmet} from "react-helmet";


function Services() {
  return (
    <React.Fragment>
        <Helmet>
            <title>Services</title>
            <meta name="description" content="Vortexx offers Management, Investment and Security services bringing your peace of mind and power in growing your porfolio." />
        </Helmet>
      <AboutCards />
      <Appointment />
    </React.Fragment>
  );
}

export default Services;
