import React from "react";
/* eslint-disable react/prop-types */
const Button = ({ title, padding, onClick, contained, margin, leftImage, rightImage, ...rest }) => {
  return (
    <button
      className="nav-item nav_num"
      onClick={onClick}
      style={
        contained
          ? {
              backgroundImage: 'linear-gradient(to right, #ff6600, #ff9147)',
              padding: padding,
              borderRadius: '5px',
              color: '#fff',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              margin: margin
            }
          : {
              background: 'none',
              padding: padding,
              borderRadius: '5px',
              color: '#000',
              border: '1px solid #ff6600',
              margin: margin
            }
      }
      {...rest}>
      {leftImage}
      {title}
      {rightImage}
    </button>
  );
};

export default Button;
