import Bitmap from '../Assets/images/Bitmap.png';

export const blogData = [
  {
    heading: 'BLOGS',
    img: Bitmap,
    title: 'Should You Buy A House During A Recession?',
    right: true,
    description:
      'Recessions and declining property values are not a new phenomena. Housing prices plummeted during the 1929 Great Depression, and in retrospect, that housing...'
  },
  {
    img: Bitmap,
    title: 'A Guide To Tennessee Property Tax',
    right: false,
    description:
      'Tennessee boasts some of the nations lowest property taxes. The median annual property tax paid by...'
  },
  {
    img: Bitmap,
    title: '5 Best Rental Markets in Tennessee for 2022',
    right: true,
    description:
      'According to Realtor.com, Tennessee is one of the two real estate markets to watch out for this year...'
  },
  {
    img: Bitmap,
    title: 'Strategies To Use Your Rental Property’s Equity To Acquire More',
    right: false,
    description:
      'Home values in most cities have risen dramatically in recent years, resulting in considerable equity gains for buy-and-hold real...'
  }
];
