import React from "react";
import Bitmap from '../../Assets/images/Bitmap.png';
import bit from '../../Assets/images/bit.png';
import path from '../../Assets/images/path.png';
import sec from '../../Assets/images/sec.png';
import { Helmet } from 'react-helmet';
import ServiceForm from '../../Component/ServiceForm';

const Security = () => {
  return (
    <React.Fragment>
        <Helmet>
            <title>Security</title>
            <meta name="description" content="See our Security offering." />
        </Helmet>
      <section className="banner_inde padding_tb ">
        <div className="container">
          <div className="two_grids">
            <div className="inr_grid">
              <h3 className="gensis-font p-color">SERVICES</h3>
              <h2 className="M-Bold_60 hd-color MR-reg-60-1">Security</h2>
              
              <p className="hd-color">
              Security is essential in todays age. With a team of experienced and knowledgeable professionals, we are dedicated to securing and protecting your assets.
              Let us assist you in protecting your assets with our industry-leading security through Vortexx.  
              
              </p>
            </div>
            
          </div>
        </div>
      </section>
      
      <section className="tation " >
        <div className="container">
          <div className="grid_mtwo">
            <div className="inr_grid">
              <h3 className="hd-color MR-reg-60">
                Security
                <br />
                <span className="p-color M-Bold_60">Simplified</span>
              </h3>
              <p className=" hd-color">
                With security being a top priority Vortexx makes it effortless. We offer solutions to keep you and your assets 
                secure around the clock with our security packages. 
              </p>
              <p className=" hd-color">
                Detect, view and quickly respond to events right from your phone from a simple to use mobile app. 
                
              </p>
              <ul className="main_ul">
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Fast video footage review. Send and share footage with just a few clicks.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Send live links to local authorities within seconds and no external hardware needed.{' '}
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  We back our security system as a zero-touch, no maintenance system.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Quick, simple install streamlining your ability to secure your property. Contact us today to find out more.
                </li>
                
              </ul>
            </div>
            <div className="inr_grid wid">
              <img src={sec} />
            </div>
          </div>
        </div>
      </section>
      <ServiceForm />
    </React.Fragment>
    
  );
};

export default Security;
