import React from "react";
import { useRoutes } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
import Portfolio from '../pages/Portfolio';
import Privacy from '../pages/Privacy';
import Services from '../pages/Services';
import Terms from '../pages/Terms';
import PropertyManagement from '../pages/PropertyManagement';
import Security from '../pages/Security';

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/blogs', element: <Blog /> },
    { path: '/contact', element: <Contact /> },
    { path: '/portfolio', element: <Portfolio /> },
    { path: '/privacy', element: <Privacy /> },
    { path: '/services', element: <Services /> },
    { path: '/terms', element: <Terms /> },
    { path: '/property-management', element: <PropertyManagement/> },
    { path: '/security', element: <Security /> },
  ]);
}
