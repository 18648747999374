import React from "react";
import facebook from '../../Assets/images/face-logo.png';
import twitter from '../../Assets/images/twitter-logo.png';
import instagram from '../../Assets/images/inst-logo.png';
import address from '../../Assets/images/add.png';
import arrow from '../../Assets/images/org-arrow.png';
import phone from '../../Assets/images/Phone.png';
import email from '../../Assets/images/Mail.png';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="footer padd-ft-tp padd-footer-mob">
      <div className="container">
        <div className="ftr-grid">
          <div className="inr-ftr-grid text_c">
            <a href="#">
              <img src="./images/new_logo.png" className="logo-footer" />
            </a>
            <div className="f-logo-inr">
              <a href="https://www.facebook.com/" target="_blank">
                <img src={facebook} className="img-auto" />
              </a>
              <a href="https://www.twitter.com/" target="_blank">
                <img src={twitter} className="img-auto" />
              </a>
              <a href="https://www.instagram.com/" target="_blank">
                <img src={instagram} className="img-auto" />
              </a>
            </div>
          </div>
          <div className="inr-ftr-grid mql" >
            <h3 className="ftr-ql M-Bold_20 hd-wht">QUICK LINKS</h3>
            <div className="inr-ftr-ul d-flex align-items-start " >
              <ul>
                <a onClick={() => navigate('/')}>
                  <li>Home</li>
                </a>
                <a onClick={() => navigate('/about')}>
                  <li>About</li>
                </a>
                <a onClick={() => navigate('/services')}>
                  <li>Services</li>
                </a>
                <a onClick={() => navigate('/portfolio')}>
                  <li>Portfolio</li>
                </a>
              </ul>
              <ul>
                <a onClick={() => navigate('/privacy')}>
                  <li>Privacy Policy</li>
                </a>
                <a onClick={() => navigate('/terms')}>
                  <li>Terms and Conditions</li>
                </a>
              </ul>
            </div>
          </div>
          <div className="inr-ftr-grid thr-ftr-col">
            <h3 className="ftr-ql M-Bold_20 hd-wht mb-3">OUR LOCATION</h3>
            <h4 className="MR-SB-16 hd-wht">
              <img src={address} className="img-auto me-2" />
              Address
            </h4>
            <p className="gray-col mt-2 mb-3">
              2000 Mallory Lane <br />
              Suite 130-537 <br />
              Franklin, TN 37067 <br />
            </p>
            <a className="get-d mt-2 mt-lg-4">
              Get Directions <img src={arrow} className="img-auto me-2" />
            </a>
          </div>
          <div className="inr-ftr-grid pt-2 pt-lg-4 mt-3">
            <h4 className="MR-SB-16 hd-wht">
              <img src={phone} className="img-auto me-2" />
              Phone
            </h4>
            <a className=" gray-col mt-2 mb-2 txt-deco" href="tel:(844) 398-1188">
              (844) 398-1188
            </a>
            <h4 className="MR-SB-16 hd-wht mt-4">
              <img src={email} className="img-auto me-2" />
              Email
            </h4>
            <a
              className=" hd-wht gray-col mt-2 mb-2 txt-deco"
              href="mailto:info@vortexx.co">
              info@vortexx.co
            </a>
          </div>
        </div>
      </div>
      <div className="footer-dp">
        <div className="container-fluid pt-3 pb-4">
          <div className="w-100 d-flex flex-column flex-column-reverse flex-lg-row align-items-center">
            <div className="d-flex align-items-center justify-content-center w-25"></div>
            <div className="d-flex align-items-center justify-content-center w-50 pt-4">
              <p className=" gray-col text-center">
                <span className="Mb_bold_16">VORTEXX ENTERPRISES, LLC </span>© 2023. All Rights
                Reserved.
              </p>
            </div>
            <div className="d-flex align-items-start justify-content-end w-25-div"></div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
