export const testimonialData = [
  {
    title: 'William Watson',
    span: 'world',
    decs: 'We have been renting our apartment in midtown through Vortexx property management for over a year. We couldn’t be happier with the service Timothy and his team provide - they are professional, friendly, honest, and always available. I can’t recommend Vortexx more highly.'
  },
  {
    title: 'Mary Easter',
    span: 'world',
    decs: 'Timothy is extremely knowledgeable and responsive, and he has been a great help to us with any issues that have come up. He is always available to take our calls and answer our questions, even on weekends and after hours. We would highly recommend Vortexx to anyone looking for a property management service!'
  },
  {
    title: 'Jared Sutton',
    span: 'world',
    decs: 'I have been a landlord for about 10 years and the one thing that has always caused me stress is finding good tenants. That is why I was so happy to find Vortexx Property Management, they are great! They even helped me find new tenants when my old ones moved out!'
  },
  {
    title: 'Alfred Bradley Wing',
    span: 'world',
    decs: 'Vortexx is a very professional and responsive property manager. They are easy to reach, they respond almost immediately and they are very knowledgeable about the real estate market.'
  },
  {
    title: 'Angela Johnson',
    span: 'world',
    decs: 'Vortexx is a great property management company that I would recommend to any landlord. We were nervous about renting our house for the first time and Timothy was very helpful and informative. He always got back to us when we had questions or concerns and this made a huge difference for us.'
  },
  {
    title: 'James McKeever',
    span: 'world',
    decs: 'The team over at Vortexx is great. They are easy to work with, communicate quickly and clearly, and offer a lot of helpful advice. We have been very happy with their service!'
  },
  {
    title: 'Samantha Collins',
    span: 'world',
    decs: 'We were so fortunate to find Vortexx Property Management. We have worked with them for the past year and a half and have been most impressed with the great customer service that we have received.'
  },
  {
    title: 'Dwayne C. Page',
    span: 'world',
    decs: 'I found Vortexx when I was looking for a property management company to work with for our new apartment in Nashville. I had no idea what to expect and I’m still amazed at how easy it was to work with them.They were super responsive over email and phone, and they made themselves very available both before and after we signed our lease. We had a ton of questions along the way, especially since this was our first time renting an apartment, and they answered them all with confidence.'
  },
  {
    title: 'Jose Ortiz',
    span: 'world',
    decs: 'Vortexx is a top-notch property manager. Their service and communication are absolutely outstanding. They helped us through the process of renting our unit. They’ve been incredibly responsive and their advice was always spot on. We cannot recommend this company more highly.'
  }
];
