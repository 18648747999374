import React from "react";
import Slider from 'react-slick';
import gog from '../../Assets/images/gog.png';
import star from '../../Assets/images/star.png';
import comas from '../../Assets/images/comas.png';
import { testimonialData } from '../../mocks/testimonials-data';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const TestimonialSlider = () => {
  return (
    <section className="owl_carousel_fr padding_tb bg_color fir-owl-carouse">
      <div className="mian-ts">
        <div className="container">
          <div className="owl_hd mb-4">
            <h3 className="gensis-font p-color text-center">CLIENT TESTIMONIALS</h3>
            <h3 className="hd-color MR-reg-60 text-center">
              {' '}
              Our Clients Are <span className="p-color M-Bold_60">Important</span> To Us
            </h3>
          </div>
          <Slider {...settings} className="owl-carousel">
            {testimonialData.map((item, ind) => {
              return (
                <div key={ind} className="owl-item item">
                  <div className="inr_owl_carousel">
                    <div className="cardo">
                      <img src={gog} className="img-auto card-img0" />
                      <div className="main-dp-h-cr">
                        <img src={star} className="img-auto" />
                        <img src={star} className="img-auto" />
                        <img src={star} className="img-auto" />
                        <img src={star} className="img-auto" />
                        <img src={star} className="img-auto" />
                      </div>
                      <div className="card-contento">
                        <h3 className="MR-SB-18 hd-color">{item.title}</h3>
                      </div>
                    </div>
                    <div className="bloc-cd">
                      <img src={comas} className="img-auto" />
                      <p className="hd-color">
                        {' '}
                        <span className="d-opa">{item.span}</span>
                        {item.decs}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
