import React from "react";
import { blogData } from '../../mocks/blogs-data';
import {Helmet} from "react-helmet";


function Blog() {
  return (
    <React.Fragment>
        <Helmet>
            <title>Blog</title>
            <meta name="description"
                  content="Updates from Vortexx, see what we're up to." />
        </Helmet>
      <section className="banner_inde padding_tb">
        <div className="container d-flex flex-column gap-5">
          {blogData.map(({ title, img, description, heading }, ind) => {
            return (
              <div key={ind}>
                <h3 className="gensis-font p-color">{heading}</h3>
                <div className="two_grids d-flex flex-lg-column">
                  <div className="inr_grid d-flex justify-content-left">
                    <img src={img} className="w-50 blog-img" />
                  </div>
                  <div className="px-lg-20 d-flex justify-content-left mt-2">
                    <div className="w-lg-50 d-flex flex-column">
                      <a
                        href="https://post.vortexx.co/2022/08/04/should-you-buy-a-house-during-a-recession/"
                        className="MR-SB-28 hd-color text-left text-decoration-none">
                        {title}
                      </a>
                      <p className="MM_para_21 hd-color text-left">{description}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </React.Fragment>
  );
}

export default Blog;
