import React from "react";
import Appointment from '../../Component/Appointment';
import AboutCards from '../../Component/AboutCards';
import ServiceForm from '../../Component/ServiceForm';
import Faq from '../../Component/Faq';
import { Helmet } from 'react-helmet';

function About() {
  return (

    <React.Fragment>
        <Helmet>
            <title>About Us</title>
            <meta name="description" content="Find out what we're about. Vortexx offers Management, Investment and Security solutions." />
        </Helmet>
      <AboutCards />
      <ServiceForm />
      <Faq />
      <Appointment />
    </React.Fragment>
  );
}

export default About;
