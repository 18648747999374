import React from "react";
import check from '../../Assets/images/check-ic.png';
import rightArrow from '../../Assets/images/arrow-right.png';
import FormContainer from "../FormContainer";

const ServiceForm = () => {
  return (
    <section className="exerci padding_tb">
      <div className="container">
        <div className="grid_mtwo">
          <div className="inr_grid">
            <h3 className="hd-color MR-reg-60">
              Simplified Services for
              <br />
              <span className="p-color M-Bold_60">All of Your Needs</span>
            </h3>
            <p className="hd-color">
              Let us assist you with your management needs. We'll do the heavy lifting so
              you can focus on developing and growing a strong portfolio.
            </p>
            <div className="m_card mt-2 mt-lg-4">
              <img src={check} alt="" className="m_card-img" />
              <h2 className="m_card-title Mb_bold_22">Property Maintenance</h2>
              <div className="m_card-content"></div>
            </div>
            <div className="m_card mt-2 mt-lg-4">
              <img src={check} alt="" className="m_card-img" />
              <h2 className="m_card-title Mb_bold_22">Contract Management</h2>
              <div className="m_card-content"></div>
            </div>
            <div className="m_card mt-2 mt-lg-4">
              <img src={check} alt="" className="m_card-img" />
              <h2 className="m_card-title Mb_bold_22">Tax and Bookkeeping Services</h2>
              <div className="m_card-content"></div>
            </div>
            <div className="m_card mt-2 mt-lg-4">
              <img src={check} alt="" className="m_card-img" />
              <h2 className="m_card-title Mb_bold_22">Communications and Relations Services</h2>
              <div className="m_card-content"></div>
            </div>
            <div className="m_card mt-2 mt-lg-4">
              <img src={check} alt="" className="m_card-img" />
              <h2 className="m_card-title Mb_bold_22">Property Marketing</h2>
              <div className="m_card-content"></div>
            </div>
            <div className="m_card mt-2 mt-lg-4">
              <img src={check} alt="" className="m_card-img" />
              <h2 className="m_card-title Mb_bold_22">Property Performance Review</h2>
              <div className="m_card-content"></div>
            </div>
            <div className="m_card mt-2 mt-lg-4">
              <img src={check} alt="" className="m_card-img" />
              <h2 className="m_card-title Mb_bold_22">Procurement</h2>
              <div className="m_card-content"></div>
            </div>
            <div className="m_card mt-2 mt-lg-4">
              <img src={check} alt="" className="m_card-img" />
              <h2 className="m_card-title Mb_bold_22">Surveillance Monitoring</h2>
              <div className="m_card-content"></div>
            </div>
          </div>
          <div className="inr_grid">
            <div className="form_inp">
              <h3 className="gensis-font p-color">INQUIRE ABOUT</h3>
              <h2 className="M-Bold_46 hd-color">Our Services</h2>
              <FormContainer sidebar={true} />
              {/*<form action="#" method="post">
                <input
                  type="text"
                  placeholder="Firstname"
                  defaultValue=""
                  data-msg-required="Please enter your first name."
                  maxLength={100}
                  className="form-control"
                  name="name"
                  required=""
                />
                <input
                  type="text"
                  placeholder="Lastname"
                  defaultValue=""
                  data-msg-required="Please enter your last name."
                  maxLength={100}
                  className="form-control "
                  name="name"
                  required=""
                />
                <input
                  type="email"
                  defaultValue=""
                  placeholder="Email Address"
                  data-msg-required="Please enter your email address."
                  data-msg-email="Please enter a valid email address."
                  maxLength={100}
                  className="form-control"
                  name="email"
                  required=""
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  defaultValue=""
                  data-msg-required="Please enter your phone number."
                  maxLength={100}
                  className="form-control "
                  name="name"
                  required=""
                />
                <textarea
                  maxLength={5000}
                  placeholder="Comments or Message"
                  data-msg-required="Please enter your message."
                  rows={8}
                  className="form-control"
                  name="message"
                  required=""
                  defaultValue={''}
                />
                <div className="subbtn f_btn">
                  <button
                    type="submit"
                    value="Send Message"
                    className="submit-btn"
                    data-loading-text="sending">
                    Submit Now
                    <img src={rightArrow} />
                  </button>
                </div>
              </form>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceForm;
