import React from "react";
import FormContainer from '../FormContainer';
import arrowRight from '../../Assets/images/arrow-right.png';
import { useLocation, useNavigate } from 'react-router-dom';

const Appointment = () => {
  const navigate = useNavigate();
  return (
    <section className="main_appointment padding_tb">
      <div className="container">
        <div className="appoint">
          <h4 className="gensis-font text-center hd-wht">START YOUR JOURNEY TODAY</h4>
          <h3 className="gensis-font text-center hd-wht">
            Investing Is A Journey So Let’s <span className="M-Bold_60">Achieve It</span> Together.
          </h3>
          
          <button className="marg-auto btn-apt Mb_bold_16 hd-wht" onClick={() => navigate('/contact')}>
            
            BOOK AN APPOINTMENT <img src={arrowRight} alt="" className="img-auto ms-3" />
            
          </button>
          
          
          
         
        </div>
        <FormContainer sidebar={false}/>
      </div>
    </section>
  );
};

export default Appointment;
