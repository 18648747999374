import React from "react";
import cont from '../../Assets/images/cont-main.png';
import ContactForm from '../../Component/ContactForm';
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <React.Fragment>
      <Helmet>
            <title>Contact</title>
            <meta name="description" content="Contact us today to find out how we can help you expand and grow your portfolio. Vortexx offers a variety of solutions to help you set yourself apart from the competition." />
        </Helmet>
      <section className="banner_inde padding_tb c-bg">
        <div className="container">
          <div className="two_grids">
            <div className="inr_grid">
              <h3 className="gensis-font p-color">CONTACT US!</h3>
              <h2 className="M-Bold_80 hd-color">Get In Touch</h2>
              <p className="hd-color">
                We’d love to chat with you about your goals and how best we can help you achieve
                them. Simply fill out the form below and we’ll be in touch soon!{' '}
              </p>
            </div>
            <div className="inr_grid">
              
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
    </React.Fragment>
  );
}

export default Contact;
