import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../Assets/images/logo.png';
import phone from '../../Assets/images/phone-call.png';
import Button from '../Button';

function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" onClick={() => navigate('/')}>
            <img src={Logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mes-auto mb-2 mb-lg-0">
              <li className={pathname === '/' ? 'nav-item active' : 'nav-link'}>
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => navigate('/')}
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent">
                  Home
                </a>
              </li>
              <li className={pathname === '/about' ? 'nav-item active' : 'nav-link'}>
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => navigate('/about')}
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent">
                  About
                </a>
              </li>
              <li
                className={
                  pathname === '/services' ? 'nav-item active dropdown' : 'nav-link dropdown'
                }>
                <span
                  className="nav-link dropdown-toggle"
                  role="button"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown">
                  {' '}
                  Services{' '}
                </span>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ marginTop: '10px' }}>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => navigate('/property-management')}
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent">
                      Property Management
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => navigate('/security')}
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent">
                      Security
                    </a>
                  </li>
                </ul>
              </li>
              {/* blogs section */}
              {/*
              <li className={pathname === '/blogs' ? 'nav-item active' : 'nav-link'}>
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => navigate('/blogs')}
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent">
                  Blog
                </a>
              </li>
              */}
              <li className={pathname === '/portfolio' ? 'nav-item active' : 'nav-link'}>
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => navigate('/portfolio')}
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent">
                  Portfolio
                </a>
              </li>
              <Button
                title="(844) 398-1188"
                padding="0.3rem 1rem"
                margin="5px 0 0 5px"
                leftImage={<img src={phone} />}
              />
              <Button
                onClick={() =>  navigate('/contact') }
                title="CONTACT US"
                padding="0.3rem 1rem"
                margin="5px 0 0 5px"
                contained
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
              />
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
