import React from "react";
import aws from '../../Assets/images/aws.png';
import AHS from '../../Assets/images/AHS.png';
import premi from '../../Assets/images/premi.png';
import ash from '../../Assets/images/ash.png';
import chur from '../../Assets/images/chur.png';
import cloud from '../../Assets/images/goo-cloud.png';
import VER from '../../Assets/images/ver.png';
const PartnerBanner = () => {
  return (
    <div className="">
      <div className="main-caro">
        <div>
          <img src={aws} className="img-auto card-img0" alt="Partners" />
        </div>
        <div>
          <img src={AHS} className="img-auto card-img0" alt="Partners" />
        </div>
        <div>
          <img src={premi} className="img-auto card-img0" alt="Partners" />
        </div>
        <div>
          <img src={ash} className="img-auto card-img0" alt="Partners" />
        </div>
        <div>
          <img src={chur} className="img-auto card-img0" alt="Partners" />
        </div>
        <div>
          <img src={cloud} className="img-auto card-img0" alt="Partners" />
        </div>
        <div>
          <img src={VER} className="img-auto card-img0" alt="Partners" />
        </div>
      </div>
    </div>
  );
};

export default PartnerBanner;
